import axios from "axios";

const form = document.querySelector('#symposium-form');
if(form) {
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        grecaptcha.ready(() => {
            grecaptcha.execute(form.dataset.sitekey, {
                action: 'submit'
            }).then((token) => {

                const submitButton = form.querySelector('[type="submit"]');
                submitButton.classList.add('disabled');
                submitButton.disabled  = true;
                submitButton.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Laden...</span></div>';

                const formData = new FormData(form);
                formData.append('g-recaptcha-response', token);

                const validationAlert = form.parentNode.querySelector('#form-validation');
                validationAlert.classList.add('d-none');
                validationAlert.classList.remove('alert-danger');

                document.querySelectorAll('.invalid-feedback').forEach((element) => {
                    if(!element.classList.contains('d-none')) {
                        element.classList.add('d-none');
                    }
                });

                document.querySelectorAll('.is-invalid').forEach((element) => {
                    element.classList.remove('is-invalid');
                });

                axios.post(form.action, formData).then((response) => {
                    if(response.data.success) {
                        validationAlert.innerHTML = response.data.message;
                        validationAlert.classList.add('alert-success');
                        validationAlert.classList.remove('d-none');

                        form.innerHTML = '';
                    }
                }).catch((exception) => {
                    if(exception.response.data.errors) {
                        for(let error in exception.response.data.errors) {
                            const el = form.querySelector('[name="' + error + '"]') || form.querySelector('#' + error);
                            el.classList.add('is-invalid');
                            if(el.parentNode.querySelector('.d-none') !== null) {
                                el.parentNode.querySelector('.d-none').innerHTML = exception.response.data.errors[error].join(', ');
                                el.parentNode.querySelector('.d-none').classList.remove('d-none');
                            }
                        }
                    }
                }).finally(() => {
                    submitButton.classList.remove('disabled');
                    submitButton.disabled  = false;
                    submitButton.innerHTML = 'Verstuur bericht';
                });
            });
        });
    });
}