import {Alert, Collapse, Dropdown, Modal, Tab, Tooltip, Popover} from 'bootstrap';
import Flickity from 'flickity';
import AOS from 'aos';
import axios from 'axios';
import ModuloBox from './plugins/modulobox.js';

import './symposium-form.js';

// Init Animate On Scroll
AOS.init();

// Remove background-img border
document.querySelectorAll('img:not([src])').forEach((element) => element.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');

// Enable popovers
document.querySelectorAll('[data-bs-toggle="popover"]').forEach((el) => new Popover(el, {trigger: 'hover', placement: 'top'}));

// Smooth scroll
if(document.querySelector('.js-scroll-trigger')) {
    document.querySelectorAll('.js-scroll-trigger').forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            const href      = event.currentTarget.getAttribute("href");
            const offsetTop = document.querySelector(href).offsetTop;

            scroll({
                top:      offsetTop,
                behavior: "smooth"
            });
        });
    });
}

const submitForm = (gRecaptchaResponse) => {
    const form = document.getElementById('newsletterForm');

    const submitButton = form.querySelector('[type="submit"]');
    submitButton.classList.add('disabled');
    submitButton.disabled  = true;
    submitButton.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Laden...</span></div>';

    const formData = new FormData(form);
    formData.append('g-recaptcha-response', gRecaptchaResponse);

    const validationAlert = document.getElementById('form-validation');
    validationAlert.classList.add('d-none');
    validationAlert.classList.remove('alert-danger');

    document.querySelectorAll('.invalid-feedback').forEach((element) => {
        if(!element.classList.contains('d-none')) {
            element.classList.add('d-none');
        }
    });

    document.querySelectorAll('.is-invalid').forEach((element) => {
        element.classList.remove('is-invalid');
    });

    axios.post(form.action, formData).then((response) => {
        if(response.data.success) {
            validationAlert.innerHTML = response.data.message;
            validationAlert.classList.add('alert-success');
            validationAlert.classList.remove('d-none');

            submitButton.innerHTML = 'Inschrijven';
        }
    }).catch((exception) => {
        if(exception.response.data.errors) {
            for(let error in exception.response.data.errors) {
                const el = form.querySelector('[name="' + error + '"]') || form.querySelector('#' + error);
                el.classList.add('is-invalid');
                if(el.parentNode.querySelector('.d-none') !== null) {
                    el.parentNode.querySelector('.d-none').classList.remove('d-none');
                }
            }
        }

        submitButton.classList.remove('disabled');
        submitButton.disabled  = false;
        submitButton.innerHTML = 'Inschrijven';
    });
}

window.submitForm = submitForm;

// Add and remove class on scroll
if(document.querySelector('.logo')) {
    let scrollpos      = window.scrollY
    const header       = document.querySelector(".logo")
    const headerHeight = header.offsetHeight

    const addClassOnScroll    = () => header.classList.add("logo-shrink")
    const removeClassOnScroll = () => header.classList.remove("logo-shrink")

    window.addEventListener('scroll', () => {
        scrollpos = window.scrollY;

        if(scrollpos >= headerHeight) {
            addClassOnScroll()
        } else {
            removeClassOnScroll()
        }
    });
}

// Flickity
if(document.querySelector('.related-posts-carousel')) {
    const flkty = new Flickity('.related-posts-carousel', {
        cellAlign:  'left',
        contain:    true,
        wrapAround: true,
        arrowShape: ''
    });
}

// Youtube custom play btn
setInterval(() => {
    if(document.activeElement instanceof HTMLIFrameElement) {
        if(document.getElementById('video-cover')) {
            document.getElementById('video-cover').style.opacity  = 0;
            document.getElementById('video-player').style.opacity = 1;
        }
    }
}, 50);

// Modulobox
var modulobox = new ModuloBox({
    mediaSelector:      '.gallery figure > a, .hidden-gallery div',
    loop:               3,
    history:            true,
    controls:           ['zoom', 'play', 'fullScreen', 'download', 'share', 'close'],
    shareButtons:       ['facebook', 'googleplus', 'twitter', 'pinterest', 'linkedin', 'reddit', 'stumbleupon', 'tumblr', 'blogger', 'buffer', 'digg', 'evernote'],
    videoMaxWidth:      1440,
    minZoom:            1,
    zoomTo:             1.8,
    mouseWheel:         false,
    contextMenu:        false,
    scrollToZoom:       true,
    captionSmallDevice: false,
    thumbnails:         true,
    thumbnailsNav:      'centered',
    thumbnailSizes:     {
        1920: {
            width:  110,
            height: 80,
            gutter: 10
        },
        1280: {
            width:  90,
            height: 65,
            gutter: 10
        },
        680:  {
            width:  0,
            height: 0,
            gutter: 0
        }
    }
});

modulobox.on('beforeOpen.modulobox', function(gallery, index) {
    if(window.cubeRAF) {
        cancelAnimationFrame(window.cubeRAF);
        window.cubeRAF = false;
    }
});

modulobox.on('afterClose.modulobox', function(gallery, index) {
    if(typeof Event === 'function' && !window.cubeRAF) {
        window.dispatchEvent(new Event('scroll'));
    }
});

modulobox.init();
